import React from 'react'
import { withRouter } from 'react-router-dom'
import SignInForm from './Form'
import { Center } from 'components/Layout'
import { authentication } from 'utils/firebase'

const SignInPage = ({ history }) => (
  <Center containerStyle={{ maxWidth: 500 }}>
    <h1 style={{ marginBottom: 16 }}>Sign in</h1>
    <SignInForm auth={authentication} history={history} />
  </Center>
)

export default withRouter(SignInPage)

import { useState } from 'react'
import { Form, message } from 'antd'

import { useValidationRules } from '../data'
import PromotionForm, { FormValues } from './PromotionForm'
import ValidationRuleModalForm from './ValidationRuleModalForm'
import type { FormValues as ValidationRulesFormValues } from './ValidationRuleForm'
import {
  type ValidationCriterionInput,
  type CreateEoJPromotionMutationVariables,
  type CreateValidationRulesMutationVariables,
  ProductDiscountType,
} from 'gql/generated/graphql'

const buildPromoInput = (
  values: FormValues,
): CreateEoJPromotionMutationVariables => {
  const [start_date, expiration_date] = values.promotion_dates ?? [null, null]

  return {
    promotion: {
      name: values.name,
      banner: values.banner,
      description: values.description,
      scheme_id: values.scheme_id,
      hardware_types: values.hardware_types,
      validation_rule_ids: values.validation_rule_ids,
      journey_first_free_minutes: values.journey_first_free_minutes,
      unlock_fee_percent_off: values.unlock_fee_percent_off,
      start_date: start_date ? start_date.toISOString() : undefined,
      expiration_date: expiration_date
        ? expiration_date.toISOString()
        : undefined,
      // TODO: is this actualy used? I think it's used for the price calculation
      // But what do we do if it's unlock and journey_minutes?
      discount: {
        percent_off: values.unlock_fee_percent_off,
        type: ProductDiscountType.percent,
      },
    },
  }
}

/**
 * Convert form inputs into variables for the mutation
 */
const buildValidationRulesInput = ({
  logic,
  name,
  rules_list,
}: ValidationRulesFormValues): CreateValidationRulesMutationVariables => {
  const mappedRulesList: ValidationCriterionInput[] = rules_list.map(
    ({ property, operator, value }) => ({
      name: 'redemption.metadata',
      property,
      conditions: { [operator]: value },
    }),
  )
  return {
    validationRules: {
      logic,
      name,
      rules_list: mappedRulesList,
    },
  }
}

type Props = {
  onFinish: (values: CreateEoJPromotionMutationVariables) => Promise<void>
  saving: boolean
  values?: FormValues
}

const CombinedPromotionForm = ({ onFinish, saving, values }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    rules,
    loading: loadingRules,
    createValidationRules,
  } = useValidationRules()

  const showRuleModal = () => {
    setIsOpen(true)
  }

  const hideRuleModal = () => {
    setIsOpen(false)
  }

  const onFinishPromotionForm = (values: FormValues) => {
    const variables = buildPromoInput(values)
    onFinish(variables)
  }

  const onFinishValidationRulesForm = async (
    values: ValidationRulesFormValues,
  ) => {
    // Convert form data and submit it to the mutation
    // returning the result to add the ID to the parent form field
    const variables = buildValidationRulesInput(values)
    const { data, errors } = await createValidationRules({ variables })

    if (errors && errors?.length > 0) {
      message.error(errors[0].message)
      return null
    }

    return data?.create_validation_rule.id ?? null
  }

  return (
    <Form.Provider
      onFormFinish={async (name, { values, forms }) => {
        if (name === 'validationRulesForm') {
          const { promotionForm } = forms

          // Save the validationRules form to the API and get back the ID
          // to add to the parent form
          const newRuleId = await onFinishValidationRulesForm(
            values as ValidationRulesFormValues,
          )

          if (!newRuleId) {
            return
          }

          // One validation rule but it's an array.
          // Add the ID that was just created
          promotionForm.setFieldsValue({
            validation_rule_ids: [newRuleId],
          })

          setIsOpen(false)
        } else if (name === 'promotionForm') {
          // Submit the main form
          onFinishPromotionForm(values as FormValues)
        }
      }}
    >
      <PromotionForm
        showRuleModal={showRuleModal}
        validationRuleOptions={rules}
        loadingRules={loadingRules}
        saving={saving}
        values={values}
      />
      <ValidationRuleModalForm isOpen={isOpen} onCancel={hideRuleModal} />
    </Form.Provider>
  )
}

export default CombinedPromotionForm

/* tslint:disable */
import {
  ApolloClient,
  from,
  ApolloLink,
  ServerParseError,
  ServerError,
} from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { ErrorResponse, onError } from '@apollo/client/link/error'
import { message } from 'antd'
import apolloLogger from 'apollo-link-logger'
import { get } from 'lodash'

import { auth } from 'utils/firebase'
import config from 'constants/config'
import { isMobile } from 'utils/mobileDetect'

import cache from './cache'
import Sentry from 'utils/sentry'

export const POLL_INTERVAL = isMobile() ? 30000 : 10000
export const REDUCED_POLL_INTERVAL = isMobile() ? 60000 : 20000

const GQL_ENDPOINT = config.REACT_APP_GQL_ENDPOINT

export const errorLink = onError((errors: ErrorResponse) => {
  const { networkError } = errors
  if (get(networkError, 'message')) {
    if (networkError?.name === 'ServerParseError') {
      const { bodyText } = networkError as ServerParseError
      // Non JSON response from server, probably a cloud armor NGINX HTML response or something
      // Users normally report a "Token" error cos "Invalid token < ..." from JSON.parse, which can be misleading
      let errorText = bodyText
      try {
        // Try to parse HTML if possible to output the error a bit more readably to users
        const htmlDom = new DOMParser().parseFromString(bodyText, 'text/html')
        errorText = htmlDom.body.innerText
      } catch (_err) {
        // Never mind
      } finally {
        message.error(`Non JSON response from server: ${errorText}`)
        Sentry.captureMessage(`Non JSON response from server: ${errorText}`)
      }
      return
    }
    if (networkError?.name === 'ServerError') {
      // The following code deals with the case where a user's dashboard version is out of date (HTTP 403)
      const { result } = networkError as ServerError
      // @ts-ignore
      const { errors: additionalErrors } = result
      const hasBrowserShouldRefreshError = additionalErrors?.some(
        error => error.reason === 'CLIENT_OUT_OF_DATE',
      )
      if (hasBrowserShouldRefreshError === true) {
        // Get a random number between 5 and 20 - this will be the interval in seconds at which dashboard should refresh
        const interval = Math.floor(Math.random() * 16) + 5
        setTimeout(() => window.location.reload(), interval * 1000)
        message.error(
          'Your dashboard version is out of date. This page will automatically refresh soon',
        )
        return
      }
    }
    message.error(networkError?.message)
    Sentry.captureException(networkError)
  }
})

const httpLink = new BatchHttpLink({
  uri: GQL_ENDPOINT,
})

// eslint-disable-next-line
export const authLink = setContext(async (_, { headers }) => {
  let token: string | null = null
  let userId: string | null = null

  const currentUser = auth.currentUser

  if (currentUser) {
    token = await currentUser.getIdToken()
    userId = currentUser.uid
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-user-id': userId ? userId : null,
    },
  }
})

const omitTypename = (key, value) => {
  return key === '__typename' ? undefined : value
}

// https://github.com/apollographql/apollo-feature-requests/issues/6
// https://gist.github.com/cdelgadob/4041818430bc5802016332dbe5611570
const typenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    )
  }
  return forward(operation)
})

const client = new ApolloClient({
  name: 'Dashboard',
  version: config.REACT_APP_RELEASE,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      pollInterval: POLL_INTERVAL,
      nextFetchPolicy: 'cache-first',
    },
  },
  link: from([errorLink, typenameMiddleware, authLink, apolloLogger, httpLink]),
})

export default client

import { Card, Col, Row } from 'antd'
import { format, parseISO } from 'date-fns'
import { Flex } from '@weareberyl/design-system'

import CardSection, { SectionItem } from 'components/shared/CardSection'

import type { EoJPromotion } from '../data'
import { SCHEME_EMOJIS } from 'constants/domain'
import Check from 'components/Check'

const EoJPromotionSummary = ({ campaign }: { campaign: EoJPromotion }) => {
  const summaryItems_1: SectionItem[] = [
    {
      key: 'ID',
      value: campaign.id,
    },
    {
      key: 'Name',
      value: campaign.name,
    },
    {
      key: 'Description',
      value: campaign.description,
    },
    {
      key: 'Scheme',
      value: `${SCHEME_EMOJIS[campaign.scheme_id]}${campaign.scheme_id}`,
    },
    {
      key: 'Created at',
      value: format(parseISO(campaign.created_at), 'dd/MM/y HH:mm:ss'),
    },
  ]
  const summaryItems_2: SectionItem[] = [
    {
      key: 'Banner',
      value: campaign.banner,
    },
    {
      key: 'Active',
      value: (
        <Check
          checked={campaign.active}
          text={campaign.active ? 'Active' : 'Inactive'}
        />
      ),
    },
    {
      key: 'Starts date',
      value: campaign.start_date
        ? format(parseISO(campaign.start_date), 'dd/MM/y')
        : '-',
    },
    {
      key: 'Expiration date',
      value: campaign.expiration_date
        ? format(parseISO(campaign.expiration_date), 'dd/MM/y')
        : '-',
    },
  ]

  const summaryItems_3: SectionItem[] = [
    {
      key: 'Unlock fee',
      value: `${campaign.unlock_fee_percent_off}% off`,
    },
    {
      key: 'Free minutes',
      value: `First ${campaign.journey_first_free_minutes}mins free`,
    },
    {
      key: 'Discount',
      value: `${campaign.discount?.percent_off}% off`,
    },
  ]
  return (
    <Row gutter={[16, 16]} className="fill-height">
      <Col xs={24} xl={16}>
        <Card title={campaign.name}>
          <Flex pt={3} style={{ gap: '40px' }}>
            <CardSection pb={4} items={summaryItems_1} />
            <CardSection pb={4} items={summaryItems_2} />
          </Flex>
        </Card>
      </Col>
      <Col xs={24} xl={8}>
        <Card title="Discounts">
          <Flex pt={3} style={{ gap: '40px' }}>
            <CardSection pb={4} items={summaryItems_3} />
          </Flex>
        </Card>
      </Col>
    </Row>
  )
}

export default EoJPromotionSummary

import { useEffect } from 'react'
import { Button, Form, Input, Select, Row, Col } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { SchemeSelect } from 'components/Scheme/SchemeSelect'
import { ValidationProperty } from 'gql/generated/graphql'
import { isNotNullOrUndefined } from 'utils/types'
import { ZoneSelect, HardwareTypeSelect } from './select-fields'

const propertyOptions = [
  { value: ValidationProperty.scheme_id, label: 'Scheme ID' },
  { value: ValidationProperty.hardware_types, label: 'Hardware type' },
  { value: ValidationProperty.start_zone_id, label: 'Starting zone' },
  { value: ValidationProperty.end_zone_id, label: 'Ending zone' },
]

type RuleValueOptionsFieldProps = React.ComponentProps<typeof Select> & {
  property: ValidationProperty
}
const RuleValueOptionsField = ({
  property,
  ...selectProps
}: RuleValueOptionsFieldProps) => {
  return {
    [ValidationProperty.end_zone_id]: <ZoneSelect {...selectProps} />,
    [ValidationProperty.start_zone_id]: <ZoneSelect {...selectProps} />,
    [ValidationProperty.hardware_types]: (
      <HardwareTypeSelect {...selectProps} />
    ),
    [ValidationProperty.scheme_id]: <SchemeSelect {...selectProps} />,
  }[property]
}

type ValidationCriterionField =
  | { property: ValidationProperty; operator: 'is'; value: string }
  | { property: ValidationProperty; operator: 'in'; value: string[] }

export type FormValues = {
  name: string
  rules_list: ValidationCriterionField[]
  logic: string
}

type Props = {
  form: FormInstance
  // TODO: hyrdate with existing data for edit usecase
  values?: FormValues
}

const ValidationRulesForm = ({ form }: Props) => {
  const rulesListField: ValidationCriterionField[] = Form.useWatch(
    'rules_list',
    form,
  )

  const defaultLogic = rulesListField
    ?.map((_item, index) => index + 1)
    .join(' AND ')

  useEffect(() => {
    // Set logic to a chain of ANDs, it can be overriden if needed
    if (!form.isFieldTouched('logic')) {
      form.setFieldValue('logic', defaultLogic)
    }
  }, [defaultLogic, form])

  return (
    <Form form={form} layout="vertical" name="validationRulesForm">
      <Form.Item name="name" label="Name" rules={[{ required: true }]}>
        <Input placeholder="e.g. Is west midlands" />
      </Form.Item>
      <Form.List name="rules_list">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => {
              const previousProperties = rulesListField
                .map(r => r?.property)
                .filter(isNotNullOrUndefined)
                .reverse()
                .slice(rulesListField.length - index)

              // Disable properties that are selected in previous rows
              const remainingPropertyOptions = propertyOptions.map(option =>
                previousProperties.includes(option.value)
                  ? { ...option, disabled: true }
                  : option,
              )

              const thisField = rulesListField[index]

              return (
                <Row key={key} gutter={[10, 10]}>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, 'property']}
                      rules={[{ required: true }]}
                    >
                      <Select options={remainingPropertyOptions} />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      {...restField}
                      name={[name, 'operator']}
                      initialValue="is"
                    >
                      <Select
                        options={[
                          { value: 'is', label: 'is' },
                          { value: 'in', label: 'in' },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={15}>
                    <Form.Item
                      {...restField}
                      name={[name, 'value']}
                      rules={[{ required: true, message: 'Missing value' }]}
                    >
                      {thisField?.property ? (
                        <RuleValueOptionsField
                          property={rulesListField[index].property}
                          mode={
                            thisField.operator === 'in' ? 'multiple' : undefined
                          }
                        />
                      ) : (
                        <Input disabled />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={1}>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Col>
                </Row>
              )
            })}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
              >
                Add a rule
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item
        name="logic"
        label="Logic"
        tooltip="AND | OR logic to combine the critera of this rule. e.g. (1 AND 2) OR 3"
        initialValue={defaultLogic}
        rules={[
          {
            required: true,
          },
          {
            pattern: /^(\(?(\d+|AND|OR| )?\)?)+$/,
            message: 'Malformed logic combination',
          },
        ]}
      >
        <Input placeholder={`e.g. ${defaultLogic ?? '1'}`} />
      </Form.Item>
    </Form>
  )
}

export default ValidationRulesForm

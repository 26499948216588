import { gql, ApolloQueryResult } from '@apollo/client'
import { Switch, Tag } from 'antd'
import type { ColumnProps } from 'antd/lib/table'
import moment from 'moment'
import { Card, Flex, Heading } from '@weareberyl/design-system'

import type {
  SCHEME_PRODUCTS_LIST_table_nodes as SchemeProduct,
  SCHEME_PRODUCTS_LIST,
} from './__generated__/SCHEME_PRODUCTS_LIST'
import type { SCHEME } from 'types'

import Query from 'components/Query'
import Table from 'components/Table'
import { withScheme } from 'components/Scheme'
import { useQueryParams } from 'utils/useQueryParams'

import { ConfigTable } from './ConfigTable'
import { REWARD_TYPES } from 'constants/domain'
import HeadTitle from 'components/HeadTitle'

const id = 'products-table'

export const SCHEME_PRODUCTS_LIST_QUERY = gql`
  query SCHEME_PRODUCTS_LIST($scheme_id: ID!, $paginate: Paginate) {
    table: all_scheme_products(scheme_id: $scheme_id, paginate: $paginate) {
      nodes {
        id
        scheme_id
        name
        product_type
        configs_v3 {
          bike {
            ...ProductConfig
          }
          ebike {
            ...ProductConfig
          }
          escooter {
            ...ProductConfig
          }
          cargo_ebike {
            ...ProductConfig
          }
        }
        is_active
        quantity
        price {
          amount
          formatted_amount
        }
        is_protected
        is_discoverable
        role_whitelist
        fulfill_rewards
        validity_interval
        billable_journey_cap_sub_period
        billable_journey_cap
      }

      pagination {
        current: page
        pageSize: per_page
        total
      }
    }
    role_types {
      description
    }
  }
  fragment ProductConfig on SchemeProductConfig {
    charge_increment_duration
    minutes_chargeable
    journey_minutes_percent_off
    period_pass_max_minute_debit
    period_pass_sub_period_for_unlocks
    period_pass_unlocks_per_sub_period
    time_increment_cost {
      formatted_amount
      amount
    }
    out_of_zone_parking_charge {
      formatted_amount
      amount
    }
    out_of_service_area_parking_charge {
      formatted_amount
      amount
    }
    unlock_fee {
      formatted_amount
      amount
    }
    out_of_bundle_minute_increment_cost {
      formatted_amount
      amount
    }
    undocked_in_zone_parking_charge {
      formatted_amount
      amount
    }
  }
`

type SchemeProductComponentProps = {
  setQueryParams: (params: {
    current?: number
    pageSize?: number
    expandedProducts?: string
  }) => void
  expandedProducts?: string
} & ApolloQueryResult<SCHEME_PRODUCTS_LIST>

const SchemeProductComponent = ({
  setQueryParams,
  expandedProducts,
  ...props
}: SchemeProductComponentProps) => {
  const columns: ColumnProps<SchemeProduct>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      width: 200,
      render: ({ name, is_active, is_protected }: SchemeProduct) => {
        if (!is_active) {
          return (
            <strong>
              <s>{name}</s>
            </strong>
          )
        }
        if (is_protected) {
          return <strong>🔒 {name}</strong>
        }
        return <strong>{name}</strong>
      },
    },
    {
      title: 'Product type',
      dataIndex: 'product_type',
      render: product_type => {
        return <Tag>{product_type}</Tag>
      },
    },
    {
      title: 'Price',
      dataIndex: ['price', 'formatted_amount'],
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      render: (quantity: string) => quantity.toLocaleString(),
    },
    {
      title: 'Valid for',
      dataIndex: 'validity_interval',
      render: validity_interval =>
        validity_interval ? moment.duration(validity_interval).humanize() : '',
    },
    {
      title: 'Journey cap',
      render: ({ billable_journey_cap, billable_journey_cap_sub_period }) => {
        if (billable_journey_cap && billable_journey_cap_sub_period) {
          const duration = moment
            .duration(billable_journey_cap_sub_period)
            .humanize()
            .replace('a ', '') // e.g. "a day" -> "day"
          return `${billable_journey_cap} per ${duration}`
        }
        return null
      },
    },
    {
      title: 'Active',
      align: 'center',
      dataIndex: 'is_active',
      render: is_active => {
        return <Switch disabled checked={is_active} size="small" />
      },
    },
    {
      title: 'Is Protected',
      align: 'center',
      dataIndex: 'is_protected',
      render: is_protected => {
        return <Switch disabled checked={is_protected} size="small" />
      },
    },
    {
      title: 'Is discoverable',
      align: 'center',
      dataIndex: 'is_discoverable',
      render: is_discoverable => {
        return <Switch disabled checked={is_discoverable} size="small" />
      },
    },
    {
      title: 'Fulfills rewards',
      dataIndex: 'fulfill_rewards',
      width: 150,
      render: (rewards: string[]) => {
        return rewards.map(reward => (
          <Tag
            style={{ display: 'inline-block', marginBottom: '1ex' }}
            key={reward}
          >
            {REWARD_TYPES[reward]}
          </Tag>
        ))
      },
    },
    {
      title: 'Role whitelist',
      dataIndex: 'role_whitelist',
      width: 150,
      render: (role_whitelist: string[]) => {
        return role_whitelist.map(role => (
          <Tag
            style={{ display: 'inline-block', marginBottom: '1ex' }}
            key={role}
          >
            {role}
          </Tag>
        ))
      },
    },
  ]

  return (
    <Table<SchemeProduct>
      id={id}
      onChange={({ current, pageSize }) =>
        setQueryParams({ current, pageSize })
      }
      columns={columns}
      expandedRowRender={schemeProduct => (
        <ConfigTable schemeProduct={schemeProduct} />
      )}
      rowClassName={({ is_active, is_discoverable }: SchemeProduct) => {
        if (!is_active) {
          return 'inactive-row'
        }
        if (!is_discoverable) {
          return 'subdued-row'
        }
        return ''
      }}
      rowKey={'id'}
      expandedRowKeys={expandedProducts?.split(',')}
      onExpand={(isExpanded, { id }) => {
        let expandedIds = expandedProducts?.split(',') ?? []
        if (isExpanded) {
          expandedIds.push(id)
        } else {
          expandedIds = expandedIds.filter(i => i !== id)
        }
        setQueryParams({ expandedProducts: expandedIds.join(',') })
      }}
      expandRowByClick
      {...props}
    />
  )
}

const ProductList = ({ scheme }: { scheme: SCHEME }) => {
  const [{ current, pageSize, expandedProducts }, setQueryParams] =
    useQueryParams(id, {
      expandedProducts: '',
    })

  return (
    <>
      <HeadTitle pageTitle="Products" />
      <Query
        waitFor="data.table.nodes"
        pollInterval={0}
        query={SCHEME_PRODUCTS_LIST_QUERY}
        variables={{
          scheme_id: scheme.id,
          paginate: { page: current, per_page: pageSize },
        }}
      >
        {(props: ApolloQueryResult<SCHEME_PRODUCTS_LIST>) => (
          <Card p={5}>
            <Flex justifyContent="space-between" alignItems="flex-end" mb={5}>
              <Heading variant="callout">Products</Heading>
            </Flex>
            <SchemeProductComponent
              setQueryParams={setQueryParams}
              expandedProducts={expandedProducts}
              {...props}
            />
          </Card>
        )}
      </Query>
    </>
  )
}

export default withScheme(ProductList)

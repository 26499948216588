import { Route, Switch, Redirect } from 'react-router'
import { matchPath, withRouter } from 'react-router-dom'

import { AuthOnly, SuperAdminOnly, PermissionOnly } from 'components/Session'
import Automations from 'components/Automations'
import ModuleList from 'components/Module/List'
import JourneyList from 'components/Journey/List'
import UserList from 'components/User/List'
import JobList from 'components/Job/List'
import SchemeContext from './SchemeContext'
import SignedOut from './SignedOut'
import Account from 'components/Account'
import SoakTestList from 'components/Module/SoakTest'
import Deployment from 'components/Deployment'
import DeploymentList from 'components/Deployment/List'
import { SchemeList } from 'components/Scheme'

import * as routes from 'constants/routes'
import { getDefaultRoute } from 'components/Navigation'

import { ErrorComponent } from '../Query/utils'
import Main from 'components/App/Main'
import { useCurrentUser } from 'hooks'

const App = ({ history, location }) => {
  const [user] = useCurrentUser()

  if (matchPath(location.pathname, { path: '/', exact: true })) {
    const route = getDefaultRoute(user, '')
    history.push(route)
  }

  if (user?.id) {
    return (
      <AuthOnly.Forbidden>
        <Main>
          <Switch>
            <Redirect from={routes.SIGN_IN} to={routes.MAP} />
            <Route
              exact
              path={routes.ALL_MODULES}
              component={() => (
                <PermissionOnly.Forbidden permission="module.all">
                  <ModuleList scheme={{ id: null }} />
                </PermissionOnly.Forbidden>
              )}
            />
            <Route
              exact
              path={routes.ALL_JOURNEYS}
              component={() => (
                <SuperAdminOnly.Forbidden>
                  <JourneyList scheme={{ id: null }} />
                </SuperAdminOnly.Forbidden>
              )}
            />
            <Route
              exact
              path={routes.ALL_USERS}
              component={() => (
                <PermissionOnly.Forbidden permission="user.view">
                  <UserList scheme={{ id: null }} />
                </PermissionOnly.Forbidden>
              )}
            />
            <Route
              exact
              path={routes.ALL_JOBS}
              component={() => (
                <PermissionOnly.Forbidden permission="job.all">
                  <JobList scheme={{ id: undefined }} />
                </PermissionOnly.Forbidden>
              )}
            />
            <Route
              exact
              path={routes.SOAK_TEST}
              component={() => (
                <PermissionOnly.Forbidden permission="provision.view">
                  <SoakTestList />
                </PermissionOnly.Forbidden>
              )}
            />
            <Route
              exact
              path={routes.DEPLOYMENT}
              component={({ match }) => (
                <PermissionOnly.Forbidden permission="deployment.view">
                  <Deployment id={match.params.id} />
                </PermissionOnly.Forbidden>
              )}
            />
            <Route
              exact
              path={routes.DEPLOYMENTS}
              component={() => (
                <PermissionOnly.Forbidden permission="deployment.view">
                  <DeploymentList />
                </PermissionOnly.Forbidden>
              )}
            />
            <Route
              exact
              path={routes.AUTOMATIONS}
              component={() => (
                <SuperAdminOnly.Forbidden>
                  <Automations />
                </SuperAdminOnly.Forbidden>
              )}
            />
            <Route exact path={routes.ACCOUNT} component={Account} />
            <Route exact path={routes.SCHEMES} component={SchemeList} />
            <SchemeContext />
            <Route
              component={() => (
                <ErrorComponent
                  error={{
                    message:
                      "Page error: We couldn't find what you are looking for",
                  }}
                />
              )}
            />
          </Switch>
        </Main>
      </AuthOnly.Forbidden>
    )
  }

  return <SignedOut />
}

export default withRouter(App)

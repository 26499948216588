type DefaultConfig = {
  REACT_APP_MAPBOX_TOKEN: string
  REACT_APP_MAPBOX_STYLE_URL: string
  REACT_APP_SENTRY_URL: string
  REACT_APP_RELEASE: string
}

const defaults: DefaultConfig = {
  REACT_APP_MAPBOX_TOKEN:
    'pk.eyJ1IjoibXdpbGxtb3R0LWJsYXplIiwiYSI6ImNqZ25pazl6ZzJuZ2cyd3BqOWhqZzk4cW0ifQ.DHmmX8j4JuOrequhCsvitw',
  REACT_APP_MAPBOX_STYLE_URL:
    'mapbox://styles/mwillmott-blaze/ck02maon30g4o1cntw3dxgypc',
  REACT_APP_SENTRY_URL:
    'https://dde08c9d611d48ac9fe0bebd7a6c9909@o107471.ingest.sentry.io/1187886',
  REACT_APP_RELEASE: process.env.REACT_APP_RELEASE as string,
}

type EnvironmentConfig = {
  GOOGLE_PROJECT_ID: string
  REACT_APP_GQL_ENDPOINT: string
  REACT_APP_VR_BIKE_ENDPOINT: string | null
  REACT_APP_ENV: string
  REACT_APP_FCM_SENDER_ID: string
  REACT_APP_FCM_KEY: string
  FIREBASE_API_KEY: string
  FIREBASE_APP_ID: string
  FIREBASE_AUTH_DOMAIN: string
  STRIPE_API_KEY: string
}

const local: EnvironmentConfig = {
  GOOGLE_PROJECT_ID: 'basis-janssen-test',
  REACT_APP_GQL_ENDPOINT: 'http://localhost:4000/graphql',
  REACT_APP_VR_BIKE_ENDPOINT: 'https://virtualbike.basis-test.bike',
  REACT_APP_ENV: 'test',
  REACT_APP_FCM_SENDER_ID: '972539765123',
  REACT_APP_FCM_KEY:
    'BB6tKl_Oh1UhF4Z_3SSGgQDfoTmKIxQPQFAXUOl4drkZ8o7wL0YAnXa0cx9tzuuxgf06e1cH9AZWf_e-19t5ADA',
  FIREBASE_API_KEY: 'AIzaSyB2e6McwM-6Ja5ATpR8o8A21C1ZskITrb8',
  FIREBASE_APP_ID: '1:972539765123:web:4cb2fc6f22ef7c180856b6',
  FIREBASE_AUTH_DOMAIN: 'basis-janssen-test.firebaseapp.com',
  STRIPE_API_KEY: 'pk_test_KbFUmasmalxN8pgsp9SNsb0R',
}

const test: EnvironmentConfig = {
  GOOGLE_PROJECT_ID: 'basis-janssen-test',
  REACT_APP_GQL_ENDPOINT: 'https://api.basis-test.bike/graphql',
  REACT_APP_VR_BIKE_ENDPOINT: 'https://virtualbike.basis-test.bike',
  REACT_APP_ENV: 'test',
  REACT_APP_FCM_SENDER_ID: '972539765123',
  REACT_APP_FCM_KEY:
    'BB6tKl_Oh1UhF4Z_3SSGgQDfoTmKIxQPQFAXUOl4drkZ8o7wL0YAnXa0cx9tzuuxgf06e1cH9AZWf_e-19t5ADA',
  FIREBASE_API_KEY: 'AIzaSyB2e6McwM-6Ja5ATpR8o8A21C1ZskITrb8',
  FIREBASE_APP_ID: '1:972539765123:web:4cb2fc6f22ef7c180856b6',
  FIREBASE_AUTH_DOMAIN: 'basis-janssen-test.firebaseapp.com',
  STRIPE_API_KEY: 'pk_test_KbFUmasmalxN8pgsp9SNsb0R',
}

const development: EnvironmentConfig = {
  GOOGLE_PROJECT_ID: 'beryl-basis-development',
  REACT_APP_GQL_ENDPOINT: 'https://api.basis-development.bike/graphql',
  REACT_APP_VR_BIKE_ENDPOINT: 'https://virtualbike.basis-development.bike',
  REACT_APP_ENV: 'development',
  REACT_APP_FCM_SENDER_ID: '438888774638',
  REACT_APP_FCM_KEY:
    'BLsvzLtQ2Q9Wo_yazwedwCmFxGejxAbSbC2OZPg-UM15ONM4lHP6g6d8c-xKiDaaCf-aX7eKNZDI00_svbed9Hg',
  FIREBASE_API_KEY: 'AIzaSyA96GGskIpUtjOtcV2fGvl2CBd1GecYW5E',
  FIREBASE_APP_ID: '1:438888774638:web:a7d32fe4988193299eecff',
  FIREBASE_AUTH_DOMAIN: 'beryl-basis-development.firebaseapp.com',
  STRIPE_API_KEY: 'pk_test_5vGTWfuJovCOLIIGsukAS6nH',
}

const staging: EnvironmentConfig = {
  GOOGLE_PROJECT_ID: 'beryl-basis-staging',
  REACT_APP_GQL_ENDPOINT: 'https://api.basis-staging.bike/graphql',
  REACT_APP_VR_BIKE_ENDPOINT: null,
  REACT_APP_ENV: 'staging',
  REACT_APP_FCM_SENDER_ID: '10038825779',
  REACT_APP_FCM_KEY:
    'BKFoSCoEi0Ys5sEovdgB3NQBj_nPMq9rR6vEkeXCQE9hZDGfoGaqy4PuhA3vloKB9zmjQrVFpGoEjxLqCmsy4lY',
  FIREBASE_API_KEY: 'AIzaSyAsag7pDlTcgUcCVnpaN5hAmpkIKRF7cwU',
  FIREBASE_APP_ID: '1:10038825779:web:dc3f42d90cd41325fa6446',
  FIREBASE_AUTH_DOMAIN: 'beryl-basis-staging.firebaseapp.com',
  STRIPE_API_KEY: 'pk_test_HB2PFFlvJ1QOSR6y7qXTqucp',
}

const production: EnvironmentConfig = {
  GOOGLE_PROJECT_ID: 'beryl-basis-production',
  REACT_APP_GQL_ENDPOINT: 'https://api.basis-pdn.bike/graphql',
  REACT_APP_VR_BIKE_ENDPOINT: null,
  REACT_APP_ENV: 'production',
  REACT_APP_FCM_SENDER_ID: '750571763178',
  REACT_APP_FCM_KEY:
    'BKqxgQVaGA9r54ySPMD8PMzJrjsWn0UE3R_qEgLu51s3v9zwa_jt_FWDQfKxFni0sUyg_0vHghh_7lmqJ9RCK4s',
  FIREBASE_API_KEY: 'AIzaSyA2LJmfxUQOwIoGG-BbL3CVb04FqKQ86OQ',
  FIREBASE_APP_ID: '1:750571763178:web:5adf68d7524a2b649cae93',
  FIREBASE_AUTH_DOMAIN: 'beryl-basis-production.firebaseapp.com',
  STRIPE_API_KEY: 'pk_live_t41PIyQzS4VNP29OORxfXUet',
}

const urlMap = {
  localhost: process.env.REACT_APP_LOCAL_BACKEND ? local : test,
  'dashboard.basis-test.bike': test,
  'dashboard.basis-development.bike': development,
  'dashboard.basis-staging.bike': staging,
  'dashboard.basis-pdn.bike': production,
}

const config: DefaultConfig & EnvironmentConfig = {
  ...defaults,
  ...urlMap[window.location.hostname],
}

export default config

import { Component } from 'react'
import { AppRegistry } from 'react-native'
import { Provider as DesignProvider } from '@weareberyl/design-system'
import { ApolloProvider } from '@apollo/client'
import { Router } from 'react-router-dom'
import { StripeProvider } from 'react-stripe-elements'

import './index.less'
import App from 'components/App'
import client from 'client'
import history from 'client/history'
import HeadTitle from 'components/HeadTitle'
import { ErrorBoundary } from 'components/Query/utils'
import CurrentUserProvider from 'context/currentUser'
import ConfigProvider from 'context/configContext'
import CurrentSchemeProvider from 'context/currentScheme'
import CommissionDetailsProvider from 'context/commissionDetails'
import FeatureFlagsProvider from 'context/featureFlags'
import SessionManager from 'components/Session/manager'

import config from 'constants/config'

class Root extends Component {
  render() {
    return (
      // skipcq JS-0415
      <ErrorBoundary>
        <FeatureFlagsProvider>
          <ApolloProvider client={client}>
            <CurrentUserProvider>
              <SessionManager>
                <CurrentSchemeProvider>
                  <CommissionDetailsProvider>
                    <StripeProvider apiKey={config.STRIPE_API_KEY}>
                      <Router history={history}>
                        <DesignProvider>
                          <ConfigProvider>
                            <App />
                          </ConfigProvider>
                        </DesignProvider>
                        <HeadTitle />
                      </Router>
                    </StripeProvider>
                  </CommissionDetailsProvider>
                </CurrentSchemeProvider>
              </SessionManager>
            </CurrentUserProvider>
          </ApolloProvider>
        </FeatureFlagsProvider>
      </ErrorBoundary>
    )
  }
}

const nativeRoot = document.getElementById('root')
if (nativeRoot) {
  AppRegistry.registerComponent('App', () => Root)
  AppRegistry.runApplication('App', {
    initialProps: {},
    rootTag: nativeRoot,
  })
}

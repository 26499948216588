import { UserCredential } from 'firebase/auth'
import { useState } from 'react'
import * as routes from 'constants/routes'

import { Card, LoginForm, TextInput } from '@weareberyl/design-system'
import { getDefaultRoute } from 'components/Navigation'
import { loadCurrentUser } from 'components/Session/manager'

interface SigninFormProps {
  auth: {
    sendSignInLinkToEmail: (email: string) => Promise<void>
    signInWithEmailAndPassword: (
      email: string,
      password: string,
    ) => Promise<UserCredential>
    fetchSignInMethodsForEmail: (email: string) => Promise<Array<string>>
    signInWithGoogleUsingPopup: () => Promise<UserCredential>
  }
  history: any
}

const SigninForm = ({ auth, history }: SigninFormProps) => {
  const [email, setEmail] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [signInMethod, setSignInMethod] = useState<
    'google.com' | 'password' | 'emailLink' | ''
  >('')
  const [password, setPassword] = useState<string>('')

  const postLoginRedirectCheck = async () => {
    if (window.location.pathname.endsWith('/logout')) {
      // Redirect user away from /logout
      const user = await loadCurrentUser()
      history.push(getDefaultRoute(user, ''))
    }
  }

  const handleSubmit = async (): Promise<void> => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setError('Please enter a valid email address')
      return
    }

    setSubmitting(true)

    try {
      const signInMethods = await auth.fetchSignInMethodsForEmail(email)

      if (signInMethods.includes('google.com')) {
        setSignInMethod('google.com')
        await auth.signInWithGoogleUsingPopup()
        await postLoginRedirectCheck()
      } else if (signInMethods.includes('password')) {
        if (password !== '') {
          // This is the case when submitting the second time, now with the password field filled
          await auth.signInWithEmailAndPassword(email, password)
          await postLoginRedirectCheck()
        } else {
          setSignInMethod('password')
          setSubmitting(false)
        }
      } else {
        // This is either 'emailLink' case or new registration with email link
        await auth.sendSignInLinkToEmail(email)
        setSignInMethod('emailLink')
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        history.push(routes.MAGIC_LINK)
      }
    } catch (err) {
      setError(`${err}`)
      setSubmitting(false)
    }
  }

  return (
    <Card>
      <LoginForm
        onChangeText={email => {
          setEmail(email)
          setError('')
        }}
        onSubmit={handleSubmit}
        loading={submitting}
        error={error}
      >
        {signInMethod === 'password' && (
          <TextInput
            hideBottomBorder
            label="Password"
            onChangeText={setPassword}
            onSubmitEditing={handleSubmit}
            returnKeyType="done"
            secureTextEntry
            value={password}
            placeholder="Password"
          />
        )}
      </LoginForm>
    </Card>
  )
}

export default SigninForm

import { useState } from 'react'
import { Menu } from 'antd'
import { intersection } from 'lodash'
import { matchPath, useHistory } from 'react-router-dom'
import {
  AlertOutlined,
  GiftOutlined,
  GlobalOutlined,
  MenuOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons'

import type {
  MenuItemType as AntMenuItemType,
  SubMenuType as AntSubMenuType,
  MenuDividerType,
} from 'antd/lib/menu/hooks/useItems'

import type { CURRENT_USER } from 'types'
import { SchemeId } from 'constants/domain'
import * as routes from 'constants/routes'
import { useCurrentScheme, useCurrentUser } from 'hooks'
import { hasPermission, Permission } from 'components/Session'
import {
  getSchemesForRole,
  hasRoleForScheme,
  isSuperWorkshop,
  doSignOut,
} from 'utils/firebase/authentication'
import useFeatureFlags from 'hooks/useFeatureFlags'

export const getDefaultRoute = (
  user: CURRENT_USER | undefined,
  scheme_id: SchemeId | string,
) => {
  if (!user || !user.id) {
    return routes.SIGN_IN
  }

  if (user.is_super_admin) {
    return routes.MAP
  }

  if (hasRoleForScheme(user.roles, 'provisioner', null)) {
    return routes.SOAK_TEST
  }

  if (
    isSuperWorkshop(user.roles) ||
    getSchemesForRole(user.roles, 'workshop').find(
      id => id === parseInt(scheme_id),
    )
  ) {
    return routes.prependScheme(routes.MODULES, scheme_id.toString())
  }

  // By default, go to the map page
  return routes.MAP
}

interface MenuItemType extends AntMenuItemType {
  permission: Permission | null
}
interface SubMenuType extends Omit<AntSubMenuType, 'children'> {
  children: MenuItemType[]
  permission: Permission | null
}
type ItemType = MenuItemType | SubMenuType | MenuDividerType

const items: ItemType[] = [
  {
    label: 'Overview',
    permission: null,
    key: routes.MAP,
  },
  {
    label: 'Vehicles',
    permission: 'bike.view',
    key: routes.MODULES,
  },
  {
    label: 'Journeys',
    permission: 'journey.view',
    key: routes.JOURNEYS,
  },
  {
    label: 'Users',
    permission: 'user.view',
    key: routes.USERS,
  },
  {
    label: 'Invoices',
    permission: 'invoice.view',
    key: routes.INVOICES,
  },
  { label: 'Bays', permission: 'zone.view', key: routes.ZONES },
  { label: 'Jobs', permission: 'job.view', key: routes.JOBS },
  { type: 'divider' },
  {
    label: 'Config',
    key: 'config',
    icon: <SettingOutlined />,
    permission: null,
    children: [
      {
        label: 'Products',
        permission: 'product.view',
        key: routes.PRODUCTS,
      },
      {
        label: 'Service areas',
        permission: 'service_area.view',
        key: routes.SERVICE_AREAS,
      },
      {
        label: 'User whitelist',
        permission: 'whitelist.view',
        key: routes.WHITELISTS,
      },
      {
        label: 'Vehicle curfews',
        permission: 'vehicle_curfew.view',
        key: routes.CURFEW,
      },
    ],
  },
  {
    label: 'Global',
    key: 'global',
    icon: <GlobalOutlined />,
    permission: 'global.view',
    children: [
      {
        label: 'All vehicles',
        permission: 'module.all',
        key: routes.ALL_MODULES,
      },
      {
        label: 'All journeys',
        permission: 'super_admin',
        key: routes.ALL_JOURNEYS,
      },
      {
        label: 'All users',
        permission: 'user.view',
        key: routes.ALL_USERS,
      },
      {
        label: 'All jobs',
        permission: 'job.all',
        key: routes.ALL_JOBS,
      },
    ],
  },
  {
    label: 'Promotions',
    key: 'promotions',
    icon: <GiftOutlined />,
    permission: 'promotion.view',
    children: [
      {
        label: 'Campaigns',
        permission: 'campaign.view',
        key: routes.CAMPAIGNS,
      },
      {
        label: 'Validation rules',
        permission: 'validation_rule.view',
        key: routes.VALIDATION_RULES,
      },
    ],
  },
  {
    label: 'Advanced',
    key: 'advanced',
    icon: <AlertOutlined />,
    permission: 'advanced.view',
    children: [
      {
        label: 'Automations',
        permission: 'super_admin',
        key: routes.AUTOMATIONS,
      },
      {
        label: 'Soak test',
        permission: 'provision.view',
        key: routes.SOAK_TEST,
      },
      {
        label: 'Deployments',
        permission: 'deployment.view',
        key: routes.DEPLOYMENTS,
      },
    ],
  },
  { type: 'divider' },
  {
    label: 'Account',
    key: 'account',
    icon: <UserOutlined />,
    permission: null,
    children: [
      {
        label: 'Settings',
        permission: null,
        key: routes.ACCOUNT,
      },
      {
        label: 'Logout',
        permission: null,
        key: 'logout',
        danger: true,
        onClick: () => doSignOut(),
      },
    ],
  },
]

const filterMenuItems = (
  items: ItemType[],
  predicates: ((item: ItemType) => boolean)[],
) => {
  return (
    items
      .map(item => {
        // Filter submenu items if there are any
        if ('children' in item) {
          const children = filterMenuItems(item.children, predicates)
          return { ...item, children }
        }
        return item
      })
      // Check all predicates
      .filter(item => predicates.every(predicate => predicate(item)))
  )
}

type NavigationItemProps = {
  mobile?: boolean
}

const Navigation = ({ mobile = false }: NavigationItemProps) => {
  const { currentSchemeId } = useCurrentScheme()
  const [featureFlags] = useFeatureFlags()
  const history = useHistory()
  const [user] = useCurrentUser()
  const [openKeys, setOpenKeys] = useState<string[]>([])
  if (!user?.id) {
    return null
  }

  if (matchPath(history.location.pathname, { path: '/', exact: true })) {
    // Nothing on / so push to overview
    if (currentSchemeId) {
      history.push(getDefaultRoute(user, currentSchemeId))
    }
  }

  // Filter nav items by permission for the current user and feature flags
  const filteredItems = filterMenuItems(items, [
    // Check permissions
    item => {
      if (!currentSchemeId) {
        return false
      }
      return 'permission' in item && item.permission
        ? hasPermission(user.roles, item.permission, parseInt(currentSchemeId))
        : true
    },
    // Check feature flags
    item => {
      const featureFlag = `${item.key}_nav`
      if (featureFlag in featureFlags) {
        return featureFlags[featureFlag]
      }
      return true
    },
  ])

  const onClick = ({ key, keyPath }: { key: string; keyPath: string[] }) => {
    if (intersection(keyPath, ['global', 'advanced', 'account']).length > 0) {
      // Global routes have no scheme ID in them
      history.push(key)
    } else {
      // Prepend current scheme to the route
      const to = currentSchemeId
        ? routes.prependScheme(key, currentSchemeId)
        : key
      history.push(to)
    }
    setOpenKeys([])
  }

  // Strip scheme prefix from current location to see which key is active
  const activeKey = routes.stripScheme(history.location.pathname)
  const selectedKeys = activeKey ? [activeKey] : undefined

  // Render as a submenu for mobile, so it can be a dropdown
  if (mobile) {
    return (
      <Menu
        items={[
          {
            key: 'nav',
            children: items,
            label: 'Menu',
            icon: <MenuOutlined />,
          },
        ]}
        openKeys={openKeys}
        onOpenChange={setOpenKeys}
        onClick={onClick}
        activeKey={activeKey}
        selectedKeys={selectedKeys}
        mode="inline"
        className="mobile-nav"
      />
    )
  }

  return (
    <Menu
      mode={'inline'}
      onClick={onClick}
      items={filteredItems}
      activeKey={activeKey}
      selectedKeys={selectedKeys}
      className="side-nav"
    />
  )
}

export default Navigation

import { useState, useEffect } from 'react'
import InvalidSignIn from 'components/InvalidSignIn'
import Loading from 'components/Loading'
import { onAuthStateChanged } from 'firebase/auth'
import sentry from 'utils/sentry'

import { auth, authentication, messaging } from 'utils/firebase'
import { CURRENT_USER } from 'types'
import useCurrentUser from 'hooks/useCurrentUser'

export const defaults: CURRENT_USER = {
  email: null,
  is_super_admin: false,
  scheme_admin: [],
  id: null,
  roles: [],
}

export const loadCurrentUser = async (): Promise<CURRENT_USER> => {
  const { user_id, email, roles } = await authentication.customClaims()
  if (!user_id) {
    return defaults
  }

  // Add typename into roles array - needed by caching
  let typed_roles: authentication.IRole[] = []

  if (roles && roles.map) {
    typed_roles = roles.map(role => {
      return {
        scheme_id: role.scheme_id || null,
        role: role.role,
        org_id: role.org_id || null,
      }
    })
  }

  return {
    id: user_id,
    email: email ?? null,
    is_super_admin: authentication.isSuperAdmin(roles ?? []),
    // The following are arrays of scheme_ids for which the current user has that role
    scheme_admin: authentication.getSchemesForRole(roles ?? [], 'admin'),
    roles: typed_roles,
  }
}

export default props => {
  const [, setCurrentUser] = useCurrentUser()
  const [loading, setLoading] = useState(true)
  const [invalidSignIn, setInvalidSignin] = useState(false)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async authUser => {
      // we check for a magic link first so we ensure the user is logged in,
      // when booting the app otherwise we direct the user to the sign in page
      // when they are actually logging in via magic link.
      if (authUser) {
        const currentUser = await loadCurrentUser()
        setCurrentUser(currentUser)
        await messaging()
      } else {
        try {
          setCurrentUser(defaults)
          // if it's a magic link we'll login the user.
          await authentication.signInWithMagicLink(window.location.href)
        } catch (err) {
          // if it fails we render the invalidSignIn component
          setInvalidSignin(true)
          sentry.captureException(err)
        }
      }

      setLoading(false)
    })

    // Cleanup subscription on unmount
    return () => unsubscribe()
  }, [setCurrentUser])

  if (invalidSignIn) {
    return <InvalidSignIn onClick={() => setInvalidSignin(false)} />
  }

  if (loading) {
    return <Loading />
  }

  return props.children
}

import type { ColumnProps } from 'antd/lib/table'
import { Tag } from 'antd'
import { isNaN } from 'lodash'
import { format, parseISO } from 'date-fns'
import { Box, Flex, useThemeColors } from '@weareberyl/design-system'

import Table from 'components/Table'
import {
  ValidationRulesItem,
  defaultData,
  UseListValidationRules,
  ValidationCriterion,
} from './data'
import Copy from 'components/Copy'
import ExternalLink from 'components/Navigation/ExternalLink'

type Props = UseListValidationRules

const voucherifyUrl = (id: string) =>
  `https://app.voucherify.io/#/app/core/validation-rules/${id}/details`

const editOnVoucherify = (id: string) => {
  window.open(voucherifyUrl(id), '_blank')
}

const columns: ColumnProps<ValidationRulesItem>[] = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: name => {
      return <strong>{name}</strong>
    },
  },
  {
    title: 'ID',
    dataIndex: 'id',
    render: value => {
      return <Copy id="copy" text={value} />
    },
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    render: value => {
      return format(parseISO(value), 'dd/MM/y HH:mm:ss')
    },
  },
  {
    title: 'Action',
    dataIndex: '',
    key: 'x',
    render: (record: ValidationRulesItem) => (
      <ExternalLink onPress={() => editOnVoucherify(record.id)}>
        Edit
      </ExternalLink>
    ),
  },
]

const RuleListItem = ({ item }: { item: ValidationCriterion }) => {
  const { colors } = useThemeColors()
  switch (item.conditions.__typename) {
    case 'ValidationConditionIn':
      return (
        <Tag style={{ marginRight: 0 }} color={colors.lake}>
          <strong>{item.property}</strong> IN ({item.conditions.in.join(', ')})
        </Tag>
      )
    case 'ValidationConditionIs':
      return (
        <Tag style={{ marginRight: 0 }} color={colors.lake}>
          <strong>{item.property}</strong> IS {item.conditions.is}
        </Tag>
      )
    default:
      return null
  }
}

const RenderLogic = ({ record }: { record: ValidationRulesItem }) => {
  const tokens = record.logic.split(/(\d+)/)
  return (
    <Flex style={{ gap: '0.5ex' }}>
      {tokens.map((token, index) => {
        if (!isNaN(parseInt(token))) {
          const item = record.rules_list[parseInt(token) - 1]
          if (!item) {
            return null
          }
          // skipcq: JS-0437 No stable ID index is next best thing for non sortable static data
          return <RuleListItem key={`${item.name}-${index}`} item={item} />
        }

        // skipcq: JS-0437 No stable ID index is next best thing for non sortable static data
        return <strong key={`${token}-${index}`}>{token}</strong>
      })}
    </Flex>
  )
}

const ValidationRulesTable = ({ data, loading }: Props) => {
  // Provide a skeleton response structure
  // so the table will render with the loading state before data is loaded
  const tableData = data ?? defaultData

  return (
    <Table<ValidationRulesItem>
      id="promotions"
      columns={columns}
      rowKey={'id'}
      data={tableData}
      size="small"
      loading={loading}
      expandable={{
        expandedRowRender: record => (
          <Box pt={2} px={2}>
            <RenderLogic record={record} />
          </Box>
        ),
        expandRowByClick: true,
      }}
    />
  )
}

export default ValidationRulesTable
